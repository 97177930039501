import React from 'react'
import Img from 'gatsby-image'
import { SRLWrapper } from 'simple-react-lightbox'

import { PostSection, Title, Description, Wrapper, Column, AdditionalImages, ImagesGrid } from './styles'

import Container from 'elements/Container'
import ArrowLeft from '../../elements/Icons/arrow-left'
import LocalizedLink from '../LocalizedLink'

const PostContent = ({ data, pathname }) => {
  const options = {
    settings: {
      autoplaySpeed: 0,
      lightboxTransitionSpeed: 0.2,
      disablePanzoom: true
    }
  }
  const {
    frontmatter: { images, title, additionalImages },
    html
  } = data
  
  return (
    <PostSection>
      <Container maxWidth="100%">
        <Wrapper>
          <Column>
            <LocalizedLink to={`/${pathname.split('/')[1]}`}>
              <ArrowLeft className="arrow-left" />
            </LocalizedLink>
            {additionalImages.length > 0 && (
              <AdditionalImages>
                {additionalImages.map(({ childImageSharp: { fluid } }, index) => {
                  return (
                    <div key={`image-${index}`}>
                      <Img fluid={fluid} alt="" className="grid-image" />
                    </div>
                  )
                })}
              </AdditionalImages>
            )}

            <Title>{title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: html }} />
          </Column>

          <SRLWrapper options={options}>
            <ImagesGrid>
              {images.map(({ childImageSharp: { fluid } }, index) => {
                return (
                  <figure key={`image-${index}`}>
                    <Img fluid={fluid} alt="" className="grid-image" />
                  </figure>
                )
              })}
            </ImagesGrid>
          </SRLWrapper>
        </Wrapper>
      </Container>
      {/* <Content>
       
      </Content> */}
    </PostSection>
  )
}

export default PostContent
