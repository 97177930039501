import React from 'react'
import { graphql } from 'gatsby'
import SimpleReactLightbox from 'simple-react-lightbox'
import SEO from 'components/seo'
import PostContent from 'components/Post'

const Post = ({ data, location }) => {
  const { markdownRemark } = data

  return (
    <>
      <SEO
        title={markdownRemark.frontmatter.title}
        description={markdownRemark.excerpt}
        image={markdownRemark.frontmatter.image}
      />
      <SimpleReactLightbox>
        <PostContent data={markdownRemark} pathname={location.pathname} />
      </SimpleReactLightbox>
    </>
  )
}

export const query = graphql`
  query Post($locale: String!, $title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        additionalImages {
          childImageSharp {
            fluid(quality: 100, maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        images {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
      excerpt
    }
  }
`

export default Post
