import styled from 'styled-components'

export const PostSection = styled.section`
  height: 100%;
  padding-top: 10rem;
`

export const Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;

  .arrow-left {
    margin-bottom: ${({ theme }) => theme.remToPx(35)};
    transform: scale(0.9);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

    :hover {
      transform: none;
    }
  }
`

export const Title = styled.h1`
  width: 100%;
  max-width: 400px;
  padding: 0px 3rem 0px 0px;
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  font-weight: ${({ theme }) => theme.fontWeight['bold']};
  line-height: ${({ theme }) => theme.remToPx(28)};
  margin: ${({ theme }) => theme.remToPx(30)} 0;
`

export const Description = styled.div`
  width: 100%;
  max-width: 570px;
  padding: 0 3rem 0 0;
  text-align: justify;
  margin-right: 5rem;

  p {
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.fontSize.sm};
    padding: 0.4rem 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
    margin-right: 0;
  }
`
export const AdditionalImages = styled.div`
  display: flex;

  div {
    display: flex;
    align-items: center;
    height: auto;
    width: 200px;
    margin-right: 2rem;
  }
`

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(180px, 220px);
  grid-gap: 40px 30px;
  justify-items: center;
  margin-top: ${({ theme }) => theme.remToPx(70)};

  figure {
    width: 180px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
    backface-visibility: hidden;
    transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    cursor: pointer;

    :hover {
      transform: translateY(-9px);
    }
  }

  .grid-image {
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 20px;

    figure {
      width: 150px;
      height: 190px;
    }
  }
`
