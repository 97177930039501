import React from 'react'

const ArrowLeft = ({ className, width, height }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 35 35" fill="none">
      <path
        d="M25.52 17.622H9.723M17.622 25.52l-7.9-7.898 7.9-7.9"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={17.5} cy={17.5} r={16.5} stroke="#000" strokeWidth={2} />
    </svg>
  )
}

ArrowLeft.defaultProps = {
  width: 35,
  height: 35
}

export default ArrowLeft
